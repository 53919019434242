form {
  .formActions {
    grid-column: span 4 / span 4;
  }
  .formActions {
    display: flex;
  }
  .formActions {
    align-items: center;
  }
  .formActions {
    justify-content: space-around;
  }
  .formActions {

    label {
      .expressText {
        transition: all 0.3s;
      }

      .expressTextOn {
        text-shadow: 0 0 0.5rem #fff;
      }

      .expressTextOff {
        --tw-text-opacity: 1;
        color: var(--fallback-bc,oklch(var(--bc)/var(--tw-text-opacity)));
      }

      .expressTextOff {
        --tw-text-opacity: 0.5;
      }
    }
  }
}

/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNyYy92aWV3cy9ob21lL25ldy10cmF2ZWwvaW5kZXgubW9kdWxlLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUVJO0lBQUE7RUFBa0Q7RUFBbEQ7SUFBQTtFQUFrRDtFQUFsRDtJQUFBO0VBQWtEO0VBQWxEO0lBQUE7RUFBa0Q7RUFEcEQ7O0lBR0U7TUFDRTtRQUNFLG9CQUFvQjtNQUN0Qjs7TUFFQTtRQUNFLDRCQUE0QjtNQUM5Qjs7TUFHRTtRQUFBLG9CQUF3QztRQUF4QztNQUF3Qzs7TUFBeEM7UUFBQTtNQUF3QztJQUU1QztFQUNGO0FBQ0YiLCJmaWxlIjoic3JjL3ZpZXdzL2hvbWUvbmV3LXRyYXZlbC9pbmRleC5tb2R1bGUuY3NzIiwic291cmNlc0NvbnRlbnQiOlsiZm9ybSB7XHJcbiAgLmZvcm1BY3Rpb25zIHtcclxuICAgIEBhcHBseSBmbGV4IGp1c3RpZnktYXJvdW5kIGl0ZW1zLWNlbnRlciBjb2wtc3Bhbi00O1xyXG5cclxuICAgIGxhYmVsIHtcclxuICAgICAgLmV4cHJlc3NUZXh0IHtcclxuICAgICAgICB0cmFuc2l0aW9uOiBhbGwgMC4zcztcclxuICAgICAgfVxyXG5cclxuICAgICAgLmV4cHJlc3NUZXh0T24ge1xyXG4gICAgICAgIHRleHQtc2hhZG93OiAwIDAgMC41cmVtICNmZmY7XHJcbiAgICAgIH1cclxuXHJcbiAgICAgIC5leHByZXNzVGV4dE9mZiB7XHJcbiAgICAgICAgQGFwcGx5IHRleHQtYmFzZS1jb250ZW50IHRleHQtb3BhY2l0eS01MDtcclxuICAgICAgfVxyXG4gICAgfVxyXG4gIH1cclxufVxyXG4iXX0= */